import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Complemento } from '../core/models/complemento';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-opciones-producto',
  templateUrl: './opciones-producto.component.html',
  styleUrls: ['./opciones-producto.component.scss']
})
export class OpcionesProductoComponent implements OnInit {

  cantidad:number;
  //tipo 1
  listComplementos:Complemento[]=[];
  //tipo 2
  listAlergenos:Complemento[]=[];
  //tipo 0
  nota:Complemento;
  articulo:any;
  listAgregarComplementos:Complemento[]=[];
  events:any[]=[];
  lineaId:any;
  listSendMarcados:Complemento[]=[];
  mesa:any;

  constructor(private router:Router,private route: ActivatedRoute, private miservicio:ComandasCamareroService,public dialog: MatDialog) {
    this.route.queryParams.subscribe(params => {
      this.articulo = params['art']; 
      this.mesa=params['mes'];
    });
   }

  ngOnInit() {
    //this.cantidad=7;
    //console.log(this.route.snapshot.params);
    //this.articulo=this.route.snapshot.params.linea
    //console.log(this.route.snapshot.data.items);
    //console.log(this.lineaId)
    this.route.snapshot.data.items.forEach(e => {
      switch(e.intTipo){
        case 1:
          this.listComplementos.push(e);
          break;
        
        case 2:
          this.listAlergenos.push(e);
          break;

        case 0:
          this.nota=e;
          break;
      }
    });

    //console.log( this.nota)

    this.route.snapshot.data.items.forEach(e => {
      switch(e.strMarcado){
        case 'N':
          e.strMarcado=false;
          break;

        case 'S':
          e.strMarcado=true;
          break;
      }
    });
    //console.log(this.route.snapshot.data.items);
    //console.log(this.listComplementos);
    //console.log(this.listAlergenos);
    //console.log(this.nota);
    //this.listarComplementos()
  }


  f1(){
    this.cantidad+=1;
  }

  marcarItem(event: any,e)
  {
    //this.events.push(event);
    //console.log(e)
  }

  muestraResumen(){
    this.router.navigate(['resumen', this.route.snapshot.params.idMesa])
  }

  muestraGrupo(){
    this.router.navigate(['grupo', this.route.snapshot.params.idMesa])
  }

  aceptar(){
    this.listSendMarcados.length=0;
   /* let disabled:any[]=[];
    let enabled:any[]=[];
    this.events.forEach(e => {
      if(e.checked===false){
        disabled.push(e.source.id)
      }else{
        enabled.push(e.source.id)
      }            
    });

    enabled = enabled.filter(function(item) {
      return !disabled.includes(item); 
    })

    console.log(enabled)*/

    //console.log(this.route.snapshot.data.items);
    this.route.snapshot.data.items.forEach(e => {
      switch(e.strMarcado){
        case true:
          this.listSendMarcados.push(e)
          break;
      }      
    });

    //console.log(this.nota.strNotas)
    if(this.nota.strNotas){
      this.nota.strNombre=this.nota.strNotas;
      this.listSendMarcados.push(this.nota)
    }    
    //console.log(this.listSendMarcados);
    //console.log(this.nota)
    
    this.miservicio.postComplementos(this.listSendMarcados,this.route.snapshot.params.linea).subscribe((datos:any)=>{
      if(datos.booOk===true){
        this.router.navigate(['resumen', this.route.snapshot.params.idMesa]);
      }else{
        this.mensajeError('Parece que ha ocurrido un error, intentelo más tarde.');
      }
    })

  }


  private mensajeError(data:any){
    let dialogRef = this.dialog.open(MensajeErrorComponent, {
      width: '600px',
      data: {
        'strMensaje':data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      //this.listar();
    });
   }

}
