import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHomeGuard implements CanActivate {

  constructor(private router: Router,private storageService: StorageService) { }

  canActivate() {
      let loginUserData = this.storageService.getCurrentUser();
      if(this.storageService.isAuthenticated()===true){
        if (loginUserData.strRolName==="Cliente") {
          this.router.navigate(['datos-cliente']);
          return true;
        }else if( loginUserData.strRolName==="Administrador" || loginUserData.strRolName==="Operador"){
          this.router.navigate(['list-clientes']);
          return true;
        }
      }else{
        this.storageService.logout();
      }
      return false;
    }
}
