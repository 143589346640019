import { Component, OnInit, Inject, Input } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { Pedido } from '../core/models/pedido';
import { Mesa } from '../core/models/mesa';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { VentanaPedidosComponent } from '../ventana-pedidos/ventana-pedidos.component';
import { FormControl } from '@angular/forms';
import { SesionCaducadaComponent } from '../sesion-caducada/sesion-caducada.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  button1:boolean=true;
  button2:boolean=false;
  usuario:any;
  listPedidos:Pedido[]=[];
  listMesasPedidos:Mesa[]=[];
  establecimiento:any;
  
  // numeroProductos:number;
  // @Input() nuevoArticulo: any;
  // @Input() muestraCarrito: boolean;
  // muestracarrito:boolean;

  constructor(private storageService: StorageService,private miservicio:ComandasCamareroService,public dialog: MatDialog,
    private router:Router) { }

  ngOnInit() {
    //console.log(this.storageService.getCurrentUser())
    this.usuario=this.storageService.getCurrentUser().strUserName;
    this.establecimiento=this.storageService.getCurrentSession().strEstablecimientoNombre
    //this.listarTickets();
    this.listarMesas();
    //VARIABLE BOOL
    // console.log(this.nuevoArticulo)
    //this.muestracarrito=this.muestraCarrito;
    //NUMERO ARTICULOS
    //this.numeroProductos=this.nuevoArticulo;
    var event = new Date(this.storageService.getCurrentSession().expires);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    //console.log(jsonDate)
    //console.log(today)
    if(jsonDate>today){
    }else if(jsonDate<today){
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    }
    
    
  }

  openWindow(){
    let dialogRef = this.dialog.open(SesionCaducadaComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(r => {
      //console.log('redirige al login')
      //window.open("http://infiteccontrol.infitec.es/servicios/solicita?est="+ this.storageService.getCurrentSession().strEstablecimientoId +"&pro="+ this.storageService.getCurrentSession().strProductoId +" ")
    });
  }

  listarTickets(){
    this.miservicio.getTodosPedido('','','N','').subscribe(datos=>{
      //console.log(datos);
      this.listPedidos=datos;
      //console.log(this.listPedidos)
    })
  }

  listarMesas(){
    this.miservicio.getMesas(0,0).subscribe((datos:any)=>{
      //console.log(datos);
    this.listMesasPedidos=datos;
    this.listMesasPedidos=this.listMesasPedidos.filter(e=>e.intEstado===1 || e.intEstado===2)
      //console.log(this.listMesasPedidos)
    })
  }


  getColor(intEstado) { 
    switch (intEstado) {
      //case 1:
        //return '#ffc14d';  
      case 2:
        return '#ff3333';
    }
  }

  muestraResumen(p){
    //console.log(p);
    if(location.pathname.includes('resumen')){
      
      this.router.navigate(['inicio'])

      setTimeout(() => {
        // if(p.intEstado===2){
        //   let dialogRef = this.dialog.open(VentanaPedidosComponent, {
        //     width: '600px',
        //     data: {
        //       'mesaId':p.intId
        //     }
        //   });
        //   dialogRef.afterClosed().subscribe(() => {
        //   });
        // }else{
          this.router.navigate(['resumen', p.intId]);
        // }
         }, 100); 

    }else{
      // if(p.intEstado===2){
      //   let dialogRef = this.dialog.open(VentanaPedidosComponent, {
      //     width: '600px',
      //     data: {
      //       'mesaId':p.intId
      //     }
      //   });
      //   dialogRef.afterClosed().subscribe(() => {
      //   });
      // }else{
        this.router.navigate(['resumen', p.intId]);
      // }
    }
    
    /*if(p.intEstado===2){
      let dialogRef = this.dialog.open(VentanaPedidosComponent, {
        width: '600px',
        data: {
          'mesaId':p.intId
        }
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    }else{
      this.router.navigate(['resumen', p.intId]);
    }*/
  }

  drawer2(){
    document.getElementById("contenido").style.backgroundColor="green";
    document.getElementById("miSidenav").style.display="initial";    
    //document.getElementById("miSidenav").style.width="250px";
    this.button1=false;
    this.button2=true; 
  }

  drawer3(){
    document.getElementById("contenido").style.backgroundColor="white";
    document.getElementById("miSidenav").style.display="none";    
    //document.getElementById("miSidenav").style.width="0";
    this.button2=false;
    this.button1=true;
  }

  salir(){
    this.storageService.logout();
  }

}
