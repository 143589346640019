import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { first } from 'rxjs/operators';
import { Mesa } from '../core/models/mesa';
import { VentanaPedidosComponent } from '../ventana-pedidos/ventana-pedidos.component';
import { MatDialog } from '@angular/material';
import { StorageService } from '../core/services/storage.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { PopupDesbloqueaMesaComponent } from '../popup-desbloquea-mesa/popup-desbloquea-mesa.component';


@Component({
  selector: 'app-zona',
  templateUrl: './zona.component.html',
  styleUrls: ['./zona.component.scss']
})
export class ZonaComponent implements OnInit {

  intId:any;
  listMesas:Mesa[]=[];
  private _hubConnection: HubConnection;

  constructor(private router:Router, private miservicio:ComandasCamareroService,private route: ActivatedRoute,private storageService: StorageService,
    public dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.intId=params["intId"];
    });
   }

  ngOnInit() {
    //console.log(this.storageService.getCurrentSession().user.strEntidadId)
    this.listMesas=this.route.snapshot.data.items;
    //console.log(this.route.snapshot.data.items)
    //this.muestraPedidoMesa();
    //console.log(this.route.snapshot.params.intId);
    //this.getDatosMesa();
/*
    this._hubConnection = new HubConnectionBuilder().withUrl(this.storageService.getCurrentSession().strHostSignalR+'/comandascamarero').build();
    // this._hubConnection = new HubConnectionBuilder().withUrl('http://localhost:5000/comandascamarero').build();
    this._hubConnection
      .start()
      .then()
      .catch(err=>{
        alert('Se ha cerrado la conexión, comprueba que tienes internet...');
        // window.location.reload();
      });

    this._hubConnection.on('comandascamarero', (value: any) => {
      //this.listarTickets();
      //console.log(type)
      // window.location.reload();
    });


    this._hubConnection.onclose(()=>{
      
      // window.location.reload();

      // this._hubConnection
      // .start()
      // .then()
      // .catch(err=>{
      //   alert('Se ha cerrado la conexión, comprueba que tienes internet...');
      //   window.location.reload();
      // });
     
      /******************************___________  PROCESO PARA INTENTAR RESTABLECER LA CONEXION  ___________***************************************/
      //setTimeout(function(){       
      //   this._hubConnection.start().then().catch();

      //   this._hubConnection.on('ComandasCamarero', (type: any, payload: string) => {
      //     this.listarComandas();
      // });
      /*/},3000); 

    });*/
  }

  muestraResumen(m:Mesa){
    // console.log(m);
    //SI LA MESA ESTA BLOQUEADA, SALDRA UNA VENTANA CON LA OPCION DE DESBLOQUARLA
    // if(m.booMesaBloqueada){
    //   let dialogRef = this.dialog.open(PopupDesbloqueaMesaComponent, {
    //     width: '600px',
    //     data: m
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(result)
    //     if(result){
    //       this.router.navigate(['resumen', m.intId]);
    //     }
    //   });
    // }else{
    //   this.router.navigate(['resumen', m.intId]);
    // }

    this.router.navigate(['resumen', m.intId]);

    // if(m.intEstado===2){
    //   let dialogRef = this.dialog.open(VentanaPedidosComponent, {
    //     width: '600px',
    //     data: {
    //       'mesaId':m.intId
    //     }
    //   });
    //   dialogRef.afterClosed().subscribe(() => {
    //   });
    // }else{
    //   this.router.navigate(['resumen', m.intId]);
    // }    
  }

  getColor(intEstado) { 
    switch (intEstado) {
      case 1:
        return '#ffc14d';  
      case 2:
        return '#ff3333';
    }
  }

  /*muestraPedidoMesa(){
    this.miservicio.getPedidos('','','','').pipe(first()).subscribe(datos=>{
      console.log(datos)
    })
  }*/

  /*getDatosMesa(){
    this.miservicio.getMesas(0,this.route.snapshot.params.intId).pipe(first()).subscribe(datos=>{
      console.log(datos);
    })
  }*/

  /*getMesas(){
    this.miservicio.getMesas(0,this.intId).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listMesas=datos;
    })
  }*/

}
