import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { ComandaService } from '../core/services/comanda.service';
import { first } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Resultcomanda } from '../core/models/resultcomanda';
import { Estadolineacomada } from '../core/models/estadolineacomada';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

@Component({
  selector: 'app-comanda',
  templateUrl: './comanda.component.html',
  styleUrls: ['./comanda.component.scss']
})
export class ComandaComponent implements OnInit {

  date = new FormControl(new Date());
  miFecha =  new Date();
  misComandas:Resultcomanda[]=[];
  dataTodo:Resultcomanda[]=[];
  listEstadosComanda:Estadolineacomada[]=[];
  button1:boolean=true;
  button2:boolean=false;
  holder:string;
  @ViewChild('Button1') Button1: ElementRef;
  disabled = false;

  listLineasUrgentes:any[]=[];
  strUrgente:string;
  private _hubConnection: HubConnection;


  constructor(private storageService: StorageService, private miServicio:ComandaService,public datepipe: DatePipe) { }

  ngOnInit() {
    //console.log(this.storageService.getCurrentSession())
    
    this.listarEstadosComanda();
    this.listarComandas();
    
    //console.log(this.date.value.toLocaleDateString());


    this._hubConnection = new HubConnectionBuilder().withUrl(this.storageService.getCurrentSession().strHostSignalR+'/notifyhub').build();
    this._hubConnection
      .start()
      .then()
  .catch();

    this._hubConnection.on('BroadcastMessage2', (type: any) => {
      //this.listarTickets();
      //console.log(type)
    });

  }

  pulsa(event){    
  }

  listarComandas(){
    let miFechaConvert=this.datepipe.transform(this.miFecha, 'yyyy-MM-dd'); 
    this.miServicio.getComandas(miFechaConvert,'N').pipe(first()).subscribe(datos=>{
      this.misComandas=datos;
    })
  }

  listarComandasFinalizadas(){
    let miFechaConvert=this.datepipe.transform(this.miFecha, 'yyyy-MM-dd'); 
    this.miServicio.getComandas(miFechaConvert,'S').pipe(first()).subscribe(datos=>{
      this.misComandas=datos;
    })
  }

  listarEstadosComanda(){
    this.miServicio.getEstadosComandaLinea().pipe(first()).subscribe(datos=>{
      this.listEstadosComanda=datos;          
    })
  }

  cambiaLineaTicket(cl){
    //console.log(cl)
    this.strUrgente="null";
    this.miServicio.putLineaTicket(cl,this.strUrgente).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos)
      
      let myObject:any;
      myObject=datos[0];

      let miTicket=cl.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);

      if(datos.length!=0){      
        
        this.misComandas.splice(index, 1, myObject);
        
      }

      if(datos.length===0){
        this.misComandas.splice(index, 1);
      } 
    })


    //this.callSignalR(cl.id_articulo);
    this.miServicio.postAspCore(cl.id_articulo).pipe(first()).subscribe(datos=>{
      //console.log(cl.id_articulo)
      //console.log(datos)
    })
  }

  f1(c){
    c.miListCTicketLinea.forEach(e => {
      document.getElementById(e.id_ticket_linea).style.display="initial"
    });   
    this.button1=false;
    this.button2=true; 
  }

  f2(c){
   
    c.miListCTicketLinea.forEach(e => {
      document.getElementById(e.id_ticket_linea).style.display="none";
    });    
    this.button2=false;
    this.button1=true;

    this.strUrgente="S";

    this.miServicio.putLineaTicket(this.listLineasUrgentes,this.strUrgente).pipe(first()).subscribe((datos:any)=>{
      let myObject:any;
      myObject=datos[0];

      let miTicket=c.miCTicket.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);

      if(datos.length!=0){      
        this.misComandas.splice(index, 1, myObject);
      }

      this.listLineasUrgentes.splice(0, this.listLineasUrgentes.length);
    })

  }

  marcarItem2(event: any,cl)
  {

    //console.log(event.source.id)
    if(event.checked===true){
      this.listLineasUrgentes.push(event.source.id);

    }else{
      const index = this.listLineasUrgentes.indexOf(event.source.id, 0);
      //console.log(index);
        if (index > -1) {
          this.listLineasUrgentes.splice(index, 1);
        }
    }
  }

  prepararComanda(c){
    if(c.strTestoBoton==="Preparar"){
      this.miServicio.putTicket(c,'X').pipe(first()).subscribe((datos:any)=>{
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=c.miCTicket.id_ticket;
  
        var index = this.misComandas.map(x => {
          return x.miCTicket.id_ticket;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.misComandas.splice(index, 1, myObject);
        }
      });
    }if(c.strTestoBoton==="Terminar"){
      this.miServicio.putTicket(c,'F').pipe(first()).subscribe((datos:any)=>{
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=c.miCTicket.id_ticket;
  
        var index = this.misComandas.map(x => {
          return x.miCTicket.id_ticket;
        }).indexOf(miTicket);
  
        if(datos.length===0){      
          this.misComandas.splice(index, 1);
        }
      });
    }
    
    //this.myDiv.nativeElement.innerHTML="Terminar";
    //document.getElementById(c.miCTicket.id_ticket).innerHTML="Terminar";
    //document.getElementById(c.miCTicket.n_ticket).style.display="none";
  }

  callSignalR(data:any){
    this.miServicio.postAspCore(data).pipe(first()).subscribe(datos=>{
      //console.log(datos)
    })
  }

  finalizarComanda(c){
    //console.log('boton2')
    /*this.miServicio.putTicket(c,'F').pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);
      let myObject:any;
      myObject=datos[0];

      let miTicket=c.miCTicket.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);

      if(datos.length!=0){      
        this.misComandas.splice(index, 1, myObject);
      }
    });*/
    //document.getElementById(c.miCTicket.n_ticket).style.display="inline-block";
    //document.getElementById(c.miCTicket.id_ticket).style.display="none";
  }

  pendientes(){
    document.getElementById('pendiente').style.color="#666666";
    document.getElementById('finalizada').style.color="#b3b3b3";
    this.listarComandas();
    //document.getElementById('pendiente').style.borderBottomColor="orange";
  }

  finalizadas(){
    document.getElementById('finalizada').style.color="#666666";
    document.getElementById('pendiente').style.color="#b3b3b3";
    this.listarComandasFinalizadas();
  }

  salir(){
    this.storageService.logout();
  }

}
