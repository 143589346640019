import { BrowserModule, Title }  from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { FileSelectDirective } from 'ng2-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { LoginComponent } from './login/login.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { CRespuestaService } from './login/shared/CRespuesta.service';
import { MatNativeDateModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FooterComponent } from './footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { CookieService } from 'ngx-cookie-service';
import { ComandaComponent } from './comanda/comanda.component';
import { MensajeErrorComponent } from './mensaje-error/mensaje-error.component';
import { DatePipe } from '@angular/common';
import { LoginRecuperacionComponent } from './login-recuperacion/login-recuperacion.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { HomeComponent } from './home/home.component';
import { ZonaComponent } from './zona/zona.component';
import { GrupoComponent } from './grupo/grupo.component';
import { ArticuloComponent } from './articulo/articulo.component';
import { ResumenPedidoComponent } from './resumen-pedido/resumen-pedido.component';
import { OpcionesProductoComponent } from './opciones-producto/opciones-producto.component';
import { LayoutComponent } from './layout/layout.component';
import { VentanaPedidosComponent } from './ventana-pedidos/ventana-pedidos.component';
import { Login2Component } from './login2/login2.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { Layout2Component } from './layout2/layout2.component';
import { SesionCaducadaComponent } from './sesion-caducada/sesion-caducada.component';
import { PopupDesbloqueaMesaComponent } from './popup-desbloquea-mesa/popup-desbloquea-mesa.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    InicioComponent,
    ComandaComponent,
    MensajeErrorComponent,
    LoginRecuperacionComponent,
    CambiaContrasenaComponent,
    HomeComponent,
    ZonaComponent,
    GrupoComponent,
    ArticuloComponent,
    ResumenPedidoComponent,
    OpcionesProductoComponent,
    LayoutComponent,
    VentanaPedidosComponent,
    Login2Component,
    SidemenuComponent,
    Layout2Component,
    SesionCaducadaComponent,
    PopupDesbloqueaMesaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  providers: [CRespuestaService,Title,CookieService,DatePipe],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
