import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { first } from 'rxjs/operators';
import { Zona } from '../core/models/zona';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  mesa:any;
  listZonas:Zona[]=[];

  constructor(private router:Router, private miservicio:ComandasCamareroService) { }

  ngOnInit() {
    this.getZonas();
  }

  muestraMesas(z){
    //console.log(z)
    this.router.navigate(['zona', z.intId]);
  }

  getZonas(){    
    this.mesa=0;
    this.miservicio.getZonas(this.mesa).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listZonas=datos;
    })
  }


}
