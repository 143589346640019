import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthAdminGuard } from './core/guards/auth-admin.guard';
import { FooterComponent } from './footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { ComandaComponent } from './comanda/comanda.component';
import { MensajeErrorComponent } from './mensaje-error/mensaje-error.component';
import { AuthorizatedGuard } from './core/guards/authorizated.guard';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { LoginRecuperacionComponent } from './login-recuperacion/login-recuperacion.component';
import { HomeComponent } from './home/home.component';
import { ZonaComponent } from './zona/zona.component';
import { GrupoComponent } from './grupo/grupo.component';
import { ArticuloComponent } from './articulo/articulo.component';
import { ResumenPedidoComponent } from './resumen-pedido/resumen-pedido.component';
import { OpcionesProductoComponent } from './opciones-producto/opciones-producto.component';
import { LayoutComponent } from './layout/layout.component';
import { APIResolver } from './core/interface/resolve';
import { VentanaPedidosComponent } from './ventana-pedidos/ventana-pedidos.component';
import { Login2Component } from './login2/login2.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { Layout2Component } from './layout2/layout2.component';
import { SesionCaducadaComponent } from './sesion-caducada/sesion-caducada.component';
import { PopupDesbloqueaMesaComponent } from './popup-desbloquea-mesa/popup-desbloquea-mesa.component';

const routes: Routes = [
  /************ componentes que se pueden cargar sin loguearse ***********/
  { path: 'login', component: LoginComponent },
  { path: 'login2', component: Login2Component },
  { path: 'footer', component: FooterComponent },
  //{ path: 'inicio', component: InicioComponent },
  //{ path: 'ServciosMesa', component: BotonesComponent },
  { path: 'mensajeError', component: MensajeErrorComponent },
  { path: 'cambiocontrasena', component: CambiaContrasenaComponent },
  { path: 'recuperacioncontrasena', component: LoginRecuperacionComponent },


  /********************************* Componentes de prueba comandas camarero ***************************************/

  { path: 'inicio', component: HomeComponent },
  { path: 'layout', component: LayoutComponent }, 
  { path: 'layout2', component: Layout2Component },  
  { path: 'sidemenu', component: SidemenuComponent },
  { path: 'zona/:intId', component: ZonaComponent, resolve: { items: APIResolver } },
  // { path: 'grupo/:mesaId/:pedidoId', component: GrupoComponent, resolve: { items: APIResolver } },
  { path: 'grupo/:mesaId', component: GrupoComponent, resolve: { items: APIResolver } },
  { path: 'articulo/:grupoId/:mesa', component: ArticuloComponent, resolve: { items: APIResolver }  },
  //{ path: 'resumen', component: ResumenPedidoComponent},
  { path: 'resumen/:dato', component: ResumenPedidoComponent,resolve: { items: APIResolver } },
  //{ path: 'resumen2/:dato2', component: ResumenPedidoComponent,resolve: { items: APIResolver } },
  { path: 'opciones/:idMesa/:linea', component: OpcionesProductoComponent, resolve: { items: APIResolver } },
  { path: 'pedidos', component: VentanaPedidosComponent },  
  { path: 'unlock', component: PopupDesbloqueaMesaComponent },  
  { path: 'error-sesion', component: SesionCaducadaComponent },

    /************************* rutas que necesitan autorizacion ************************/

  { path: 'comandas', component: ComandaComponent,canActivate: [ AuthorizatedGuard ]},

  /************************* Estos son rutas por defecto ************************/

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
