import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { first } from 'rxjs/operators';
import { Pedido } from '../core/models/pedido';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ventana-pedidos',
  templateUrl: './ventana-pedidos.component.html',
  styleUrls: ['./ventana-pedidos.component.scss']
})
export class VentanaPedidosComponent implements OnInit {

  mesa:any;
  listPedidos:Pedido[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any,private miservicio:ComandasCamareroService,
  private router:Router) {
    this.mesa=data.mesaId;
   }

  ngOnInit() {
    //console.log(this.mesa);
    this.muestraPedidosPendientes();
  }

  muestraPedidosPendientes(){
    this.miservicio.getCabeceraPedido('','N',this.mesa).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listPedidos=datos;
      //console.log(this.listPedidos)
    })
  }

  muestraResumen(p){
    //console.log(p);
    this.thisDialogRef.close();
    this.miservicio.getPedidoVinculado(p.intId,p.intMesaId).subscribe((datos:any)=>{
      //console.log(datos);
      this.router.navigate(['resumen', datos[0].intMesaId]);
    })
    //this.router.navigate(['resumen2', p.intId]);
  }

  cerrar(){
    this.thisDialogRef.close();
  }

}
