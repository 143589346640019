import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Establecimiento } from '../models/establecimiento';

@Injectable({
  providedIn: 'root'
})
export class SinAccesoService {

  public url: string = environment.baseUrl+'/api/';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }

  getEstablecimiento(cookie):Observable<Establecimiento[]>{
    let dato='strCookie='+cookie+'&datoAdicional=1';
    let urlCompleta=this.url+'Establecimiento?'+dato;
    return this.http.get<Establecimiento[]>(urlCompleta,  this.httpOptions);
  }
}
