import { Component, OnInit, Inject } from '@angular/core';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Mesa } from '../core/models/mesa';

@Component({
  selector: 'app-popup-desbloquea-mesa',
  templateUrl: './popup-desbloquea-mesa.component.html',
  styleUrls: ['./popup-desbloquea-mesa.component.scss']
})
export class PopupDesbloqueaMesaComponent implements OnInit {

  miMesa:Mesa;
  formMesa: FormGroup;
  submitted = false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any,private miservicio:ComandasCamareroService, private _formBuilder: FormBuilder) {
   }

  ngOnInit() {
    //console.log(this.mesa);
    this.miMesa=this.data;
    // console.log(this.miMesa)

    this.formMesa = this._formBuilder.group({
      alias: ['', Validators.required]
    })
  }

  btnGuardar(){
    this.submitted=true;

    if(this.formMesa.valid){
      // console.log(this.miMesa)
      this.desbloquearMesa();
    }
  }

  desbloquearMesa(){
    this.miservicio.unlockMesa(this.miMesa).subscribe(datos=>{
      // console.log(datos)
      if(datos.booOk){
        this.thisDialogRef.close(true);
      }
    })
  }

  cancelar(){
    this.thisDialogRef.close();
  }

}
