import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Zona } from '../models/zona';
import { Mesa } from '../models/mesa';
import { Pedido } from '../models/pedido';
import { PedidoLinea } from '../models/pedido-linea';
import { Grupo } from '../models/grupo';
import { Articulo } from '../models/articulo';
import { Complemento } from '../models/complemento';
import { PedidosLineasTipos } from '../models/pedidos-lineas-tipos';

@Injectable({
  providedIn: 'root'
})
export class ComandasCamareroService {

  constructor(private http: HttpClient, private storageService: StorageService ) { }

  public url: string = environment.baseUrl+'/api/';
  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  private camarero=this.storageService.getCurrentSession().user.strEntidadId;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getZonas(mesa):Observable<Zona[]>{
    let dato='strCookie='+this.cookie+'&intMesaId='+mesa;
    let urlCompleta=this.url+'Zona?'+dato;
 
    return this.http.get<Zona[]>(urlCompleta,this.httpOptions);
  }

  getMesas(mesa,zona):Observable<Mesa[]>{
    let dato='strCookie='+this.cookie+'&intMesaId='+mesa+'&intZonaId='+zona;
    let urlCompleta=this.url+'Mesa?'+dato;
 
    return this.http.get<Mesa[]>(urlCompleta,this.httpOptions);
  }

  unlockMesa(mesa):Observable<any>{
    let urlCompleta=this.url+'Mesa?strCookie='+this.cookie;
    var jsonString = JSON.stringify(mesa);
    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);
  }

  lockMesa(mesa):Observable<any>{
    let urlCompleta=this.url+'Mesa?strCookie='+this.cookie;
    var jsonString = JSON.stringify(mesa);
    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);
  }

  getTodosPedido(pedido,camarero,cerrado,mesa):Observable<Pedido[]>{
    let dato='strCookie='+this.cookie+'&strPedidoId='+pedido+'&strCamareroId='+camarero+'&strCerrado='+cerrado+'&strMesaId='+mesa;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'Pedido?'+dato;
 
    return this.http.get<Pedido[]>(urlCompleta,this.httpOptions);
  }

  getCabeceraPedido(pedido,cerrado,mesa):Observable<Pedido[]>{
    let dato='strCookie='+this.cookie+'&strPedidoId='+pedido+'&strCamareroId='+this.storageService.getCurrentSession().user.strEntidadId+'&strCerrado='+cerrado+'&strMesaId='+mesa;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'Pedido?'+dato;
 
    return this.http.get<Pedido[]>(urlCompleta,this.httpOptions);
  }

  getPedidoVinculado(pedido,mesa):Observable<Pedido[]>{
    let dato='strCookie='+this.cookie+'&strCamareroId='+this.storageService.getCurrentSession().user.strEntidadId+'&strMesaId='+mesa+'&strPedidoId='+pedido;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'Pedido?'+dato;
 
    return this.http.get<Pedido[]>(urlCompleta,this.httpOptions);
  }

  deletePedido(pedido):Observable<any>{
    let urlCompleta=this.url+'Pedido?strCookie='+this.cookie;
    var jsonString = JSON.stringify(pedido);
    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);
  }

  // deletePedido2(pedido,strMesaId):Observable<any>{
  //   let urlCompleta=this.url+'Pedido?strCookie='+this.cookie+'&strMesaId='+strMesaId;
  //   var jsonString = JSON.stringify(pedido);
  //   return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);
  // }

  finalizaPedido(pedido):Observable<any>{
    let urlCompleta=this.url+'Pedido/finalizar?strCookie='+this.cookie ;
    var jsonString = JSON.stringify(pedido);
    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);
  }

  getLineasPedido(pedido,strMesaId):Observable<PedidoLinea[]>{
    let dato='strCookie='+this.cookie+'&strPedidoId='+pedido+"&strMesaId="+strMesaId;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'PedidoLineaCamarero?'+dato;
 
    return this.http.get<PedidoLinea[]>(urlCompleta,this.httpOptions);
  }

  getGrupos():Observable<Grupo[]>{ 
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'grupo?'+dato;
 
    return this.http.get<Grupo[]>(urlCompleta, this.httpOptions);
  }

  getArticulos(mesa,articuloId,destacado,grupoId):Observable<Articulo[]>{ 
    let dato='strCookie='+this.cookie+'&intMesaId='+mesa+'&intArticuloId='+articuloId+'&strDestacado='+destacado+'&intGrupoId='+grupoId;
    let urlCompleta=this.url+'articulo?'+dato;
 
    return this.http.get<Articulo[]>(urlCompleta, this.httpOptions);
  }

  insertaLineaPedido(mesa,articulo,pedido):Observable<any>{
    let urlCompleta=this.url+'PedidoLineaCamarero?strCookie='+this.cookie+'&strMesaId='+mesa+'&strArticuloId='+articulo;
    urlCompleta += '&strCamareroId='+this.storageService.getCurrentSession().user.strEntidadId;
    urlCompleta += '&strPedido='+pedido;
    //let dato = '{"strMesaId": "'+ mesa +'", "strArticuloId":"'+ articulo +'", "strPedidoId":"'+ pedido +'"}';
    //var jsonString = JSON.stringify(pedido);
    let data:any;
    return this.http.post<any>(urlCompleta, data, this.httpOptions);
  }

  putLineaPedido(pedidoLinea,dato,strMesaId):Observable<any>{
    let urlCompleta=this.url+'PedidoLineaCamarero?strCookie='+this.cookie+'&strDato='+dato+"&strMesaId="+strMesaId;
    var jsonString = JSON.stringify(pedidoLinea);
    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);
  }

  deleteLineasPedido(lineas,strMesaId):Observable<any>{
    let urlCompleta=this.url+'PedidoLineaCamarero?strCookie='+this.cookie+'&strMesaId='+strMesaId;
    var jsonString = JSON.stringify(lineas);
    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);
  }

  getComplementos(linea):Observable<Complemento[]>{
    let dato='strCookie='+this.cookie+'&strLinea='+linea;
    let urlCompleta=this.url+'Complemento?'+dato;
    return this.http.get<Grupo[]>(urlCompleta, this.httpOptions);
  }

  //falta por terminar
  postComplementos(misComplementos,linea):Observable<any>{
    let urlCompleta=this.url+'Complemento?'+"strCookie="+this.cookie+'&strLineaId='+linea;
    var jsonString = JSON.stringify(misComplementos);
    return this.http.post<any>(urlCompleta, jsonString, this.httpOptions);
  }

  getPedidosLineasTipos(id):Observable<PedidosLineasTipos[]>{
    let dato='strCookie='+this.cookie+'&intId='+id;
    //dato+='&strOrigen=cc';
    let urlCompleta=this.url+'PedidosLineasTipos?'+dato;
 
    return this.http.get<PedidosLineasTipos[]>(urlCompleta,this.httpOptions);
  }

  putOrdenaPedidosLineas(intTipo,miListPedidoLineas):Observable<any>{
    let urlCompleta=this.url+'PedidosLineasTipos?'+"strCookie="+this.cookie+'&intTipo='+intTipo;
    var jsonString = JSON.stringify(miListPedidoLineas);
    return this.http.put<any>(urlCompleta, jsonString, this.httpOptions);
  }
}
