import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { Pedido } from '../core/models/pedido';
import { first } from 'rxjs/operators';
import { PedidoLinea } from '../core/models/pedido-linea';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { MatDialog } from '@angular/material';
import { PedidosLineasTipos } from '../core/models/pedidos-lineas-tipos';
import { PedidoLineaComplemento } from '../core/models/pedido-linea-complemento';
import { Mesa } from '../core/models/mesa';
import { PopupDesbloqueaMesaComponent } from '../popup-desbloquea-mesa/popup-desbloquea-mesa.component';

@Component({
  selector: 'app-resumen-pedido',
  templateUrl: './resumen-pedido.component.html',
  styleUrls: ['./resumen-pedido.component.scss']
})
export class ResumenPedidoComponent implements OnInit {

  numArticulos:number=1;
  addArticulo:boolean=true;
  listPedidos:Pedido[]=[];
  pedidoVacio:boolean=false;
  pedidoLineas:boolean=false;
  listPedidoLineas:PedidoLinea[]=[];
  listLineasEliminar:PedidoLinea[]=[];
  muestraTotalArticulos:boolean=false;
  totalArticulos:number;
  muestraSpinner:boolean=false;
  listPedidosLineasTipos:PedidosLineasTipos[]=[];
  muestraPosicion:boolean=false;
  showAddGrupo:boolean;

  //tipo 1
  listComplementos:PedidoLineaComplemento[]=[];
  //tipo 2
  listAlergenos:PedidoLineaComplemento[]=[];
  //tipo 0
  nota:PedidoLineaComplemento;

  // listMesas:Mesa[]=[];
  miMesa:Mesa=new Mesa();
  // isChecked:boolean;
  mesaActiva=false;

  constructor(private router:Router,private route: ActivatedRoute, private miservicio:ComandasCamareroService,public dialog: MatDialog) {
    /*this.route.params.subscribe(params => {
      this.intZonaId=params["intZonaId"];
    });*/
   }

  ngOnInit() {    
    // console.log(this.route.params["value"].dato)
    // console.log(this.route.snapshot.data.items)
    // console.log(this.route.snapshot.data.items.length)
    //datos que obtengo independientemente de si paso el id mesa o id pedido
    this.recuperoNombreMesa();
    if(this.route.snapshot.data.items.length>0){
      this.listPedidos=this.route.snapshot.data.items;
      this.showAddGrupo=this.route.snapshot.data.items[0].booModificaCabecera
      this.estadoMesa(this.route.snapshot.data.items[0].intEstadoMesaId);
      this.recuperaLineasPedido();
      this.recuperoTotalArticulos();
      this.recuperoPedidosLineasTipos();
    }else{
      this.pedidoVacio=true;
      this.showAddGrupo=true;
    }
  }

  desbloquear(){
      this.miMesa.booMesaBloqueada=false;
      // console.log(this.miMesa.booMesaBloqueada)
  
      let dialogRef = this.dialog.open(PopupDesbloqueaMesaComponent, {
        width: '600px',
        data: this.miMesa
      });
      dialogRef.afterClosed().subscribe(result => {
        // console.log(result)
        if(result!=true){
          // document.getElementById("lock").click();
          this.mesaActiva=false;
        }
      });    
  }

  bloquear(){
      this.miMesa.booMesaBloqueada=true;
    // console.log(this.miMesa.booMesaBloqueada)
    this.miservicio.lockMesa(this.miMesa).subscribe(datos=>{
      // console.log(datos)
      
    })
  }

  recuperoNombreMesa(){
    this.miservicio.getMesas(this.route.params["value"].dato,0).subscribe(datos=>{
      // console.log(datos[0].booMesaBloqueada)
      this.miMesa=datos[0];
      
      if(datos[0].booMesaBloqueada){
        this.mesaActiva=false;
      }else{
        this.mesaActiva=true;
      }
    })
  }

  unLock(){
    // console.log(this.mesaActiva)

    if(!this.mesaActiva){
      this.bloquear();
      //Desbloqueo
      // this.desbloquear()
    }else{
      //Bloqueo
      this.desbloquear();
    }
  }

  recuperoPedidosLineasTipos(){
    this.miservicio.getPedidosLineasTipos(0).subscribe(datos=>{
      this.listPedidosLineasTipos=datos;
    })
  }

  ordenaLineasPedido(pedidoLineaTipo){
    if(this.listLineasEliminar.length>0){
      this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
      this.miservicio.putOrdenaPedidosLineas(pedidoLineaTipo.intId,this.listLineasEliminar).subscribe((datos:any)=>{
        if(datos.booOk){
          this.recuperaLineasPedido();
        }
      })
    }else{
      let dialogRef = this.dialog.open(MensajeErrorComponent, {
        width: '600px',
        data: {
          'strMensaje':'Antes debes seleccionar alguna linea'
        }
      })
    }
    this.listLineasEliminar.splice(0, this.listLineasEliminar.length);  
  }

  recuperoTotalArticulos(){
    this.miservicio.getLineasPedido(this.route.snapshot.data.items[0].intId,this.route.params["value"].dato).subscribe((datos:any)=>{
      if(datos.length>0){
        this.totalArticulos=datos.pop().decTotalArticulos
        this.muestraTotalArticulos=true;       
        this.muestraSpinner=false;         
      }
    })
  }


  estadoMesa(estado) { 
    switch (estado) {
      case 1:      
        return this.pedidoLineas=true;  
      case 3:
        return this.pedidoVacio=true;
    }
  }

  recuperaLineasPedido(){
    this.miservicio.getLineasPedido(this.listPedidos[0].intId,this.route.params["value"].dato).subscribe((datos:any)=>{
      // console.log(datos);
      this.listPedidoLineas=datos;          
    })
  }

  eliminaPedido(){
    // console.log(77)
    this.miservicio.deletePedido(this.listPedidos[0]).subscribe((datos:any)=>{
      // console.log(datos);
      if(datos.booOk===true){
        this.router.navigate(['inicio'])
      }
    })
  }

  finalizaPedido(){
    this.miservicio.finalizaPedido(this.listPedidos[0]).subscribe((datos:any)=>{
      //console.log(datos);
      if(datos.booOk===true){
        this.router.navigate(['inicio'])
      }
    })
  }

  menos(l){
    if(l.decCantidad===1){
      this.mensajeError('La cantidad no puede ser menor');
    }else{
      this.miservicio.putLineaPedido(l,"menos",this.route.params["value"].dato).subscribe((datos:any)=>{

      let myObject:any;
      myObject=datos.miListPedidoLinea[0];
      let miLinea=l.intId;

      var index = this.listPedidoLineas.map(x => {
        return x.intId;
      }).indexOf(miLinea);
      
        this.listPedidoLineas.splice(index, 1, myObject)
        this.listPedidos=datos.miListPedidos;

        this.muestraSpinner=true;
        this.muestraTotalArticulos=false;
        this.recuperoTotalArticulos();
      })
    }    
  }

  mas(l){
    this.miservicio.putLineaPedido(l,"mas",this.route.params["value"].dato).subscribe((datos:any)=>{
      //console.log(datos)
      let myObject:any;
      myObject=datos.miListPedidoLinea[0];
      let miLinea=l.intId;

      var index = this.listPedidoLineas.map(x => {
        return x.intId;
      }).indexOf(miLinea);
      
        this.listPedidoLineas.splice(index, 1, myObject)
        this.listPedidos=datos.miListPedidos;

        this.muestraSpinner=true;
        this.muestraTotalArticulos=false;
        this.recuperoTotalArticulos();
    })
  }

  marcarItem(event: any,l)
  {
    if(event.checked){
      this.listLineasEliminar.push(l);
    }else{
      var index = this.listLineasEliminar.map(x => {
        return x.intId;
      }).indexOf(l.intId);

        this.listLineasEliminar.splice(index, 1);
    }
  }

  eliminar(){
    if(this.listLineasEliminar.length>0){
      this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
      this.miservicio.deleteLineasPedido(this.listLineasEliminar,this.route.params["value"].dato).subscribe(datos=>{
        //console.log(datos)
        
        if(datos.miRespuesta.strValor==="0"){
          this.pedidoLineas=false;
          this.pedidoVacio=true; 
        }else{
          this.listPedidoLineas=datos.miListPedidoLinea;
          //actualizo el importe total de la cabecera
          this.listPedidos=datos.miListPedidos;
        }
  
        this.muestraSpinner=true;
        this.muestraTotalArticulos=false;
        this.recuperoTotalArticulos();
      })
    }else{
      let dialogRef = this.dialog.open(MensajeErrorComponent, {
        width: '600px',
        data: {
          'strMensaje':'Antes debes seleccionar alguna linea'
        }
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    }  
    this.listLineasEliminar.splice(0, this.listLineasEliminar.length);  
  }

  muestraGrupos(){
    // this.router.navigate(['grupo', this.route.params["value"].dato, this.route.snapshot.data.items[0].intId]);
    // console.log(this.route.params["value"].dato)
    this.router.navigate(['grupo', this.route.params["value"].dato]);
  }

  muestraMesas(){
    // console.log(this.listPedidos[0].intZonaId)    
    // this.router.navigate(['zona', this.listPedidos[0].intZonaId]);
    this.router.navigate(['zona', this.miMesa.intZonaId]);
  }

  muestraOpciones(l){

   //Muestro las opciones solamente si es una linea que no se ha enviado al tpv
   if(l.booModificaLinea){
      let extra: NavigationExtras = {
        queryParams: {
          'art':l.strArticuloNombre,
          'mes':this.listPedidos[0].strMesaNombre
        }
      }
      this.router.navigate(['opciones', this.route.params["value"].dato, l.intId],extra);  
   }else{
      this.mensajeError('No puedes modificar una linea que ya se ha enviado al TPV');
   }
    
  }

  private mensajeError(data:any){
    let dialogRef = this.dialog.open(MensajeErrorComponent, {
      width: '600px',
      data: {
        'strMensaje':data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
   }

}
