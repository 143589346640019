
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {StorageService} from "../services/storage.service";

@Injectable()
export class AuthorizatedGuard implements CanActivate  {

  constructor(  private storageService: StorageService) { }

  canActivate() {
    let loginUserData = this.storageService.getCurrentUser();
    //if (this.storageService.isAuthenticated() && loginUserData.strRolName==="Administrador" || loginUserData.strRolName==="Operador") {

    if (this.storageService.isAuthenticated()) {
      // logged in so return true
      return true;
    }
    this.storageService.logout();
    return false;
  }

}
