import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {

  constructor(private storageService: StorageService) { }

    canActivate() {
      let loginUserData = this.storageService.getCurrentUser();
      if (this.storageService.isAuthenticated() && loginUserData.strRolName==="Administrador") {
        // logged in so return true
        return true;
      }
      this.storageService.logout();
      return false;
    }
}
