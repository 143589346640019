import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MockBackend} from "@angular/http/testing";
import {BaseRequestOptions} from "@angular/http";
import {StorageService} from "./services/storage.service";
import {AuthorizatedGuard} from "./guards/authorizated.guard";
import { AuthAdminGuard } from './guards/auth-admin.guard';
import { AuthChildrenGuardGuard } from './guards/auth-children-guard.guard';
import { AuthHomeGuard } from './guards/auth-home.guard';
import { ComandaService } from './services/comanda.service';
import { SinAccesoService } from './services/sin-acceso.service';
import { ComandasCamareroService } from './services/comandas-camarero.service';
import { APIResolver } from './interface/resolve';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    StorageService,
    AuthorizatedGuard,
    AuthAdminGuard,
    AuthChildrenGuardGuard,
    AuthHomeGuard,
    MockBackend,
    BaseRequestOptions,
    ComandaService,
    ComandasCamareroService,
    SinAccesoService,
    APIResolver
  ],
  bootstrap: []
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
