import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { first } from 'rxjs/operators';
import { Grupo } from '../core/models/grupo';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.scss']
})
export class GrupoComponent implements OnInit {

  listGrupo:Grupo[]=[];
  muestraTotalArticulos:boolean=false;
  totalArticulos:number;
  breakpoint:any;
  numPedido:any;

  constructor(private router:Router,private route: ActivatedRoute,private miservicio:ComandasCamareroService) { }

  ngOnInit() {
    //console.log(this.route.snapshot.params.mesaId);
    this.mostrarGrupos();
    this.recuperoTotalArticulos();

    this.breakpoint = (window.innerWidth <= 500) ? 2 : 4;

    if(this.route.snapshot.params.pedidoId!=undefined){
      this.numPedido=this.route.snapshot.params.pedidoId;
    }
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 500) ? 2 : 4;
  }

  recuperoTotalArticulos(){
    if(this.route.snapshot.params.pedidoId===undefined){
      this.miservicio.getCabeceraPedido('','N',this.route.snapshot.params.mesaId).subscribe((datos:any)=>{
        // console.log(datos);
        if(datos.length>0){
          this.numPedido=datos[0].intId;

          this.miservicio.getLineasPedido(this.numPedido,this.route.snapshot.params.mesaId).subscribe((datos:any)=>{
            //console.log(datos)
            //console.log(datos.length)
            if(datos.length>0){
              this.totalArticulos=datos.pop().decTotalArticulos
              //console.log(datos.pop().decTotalArticulos)
              this.muestraTotalArticulos=true;                
            }
          })
        }        
      })      
    // console.log(this.numPedido)
      
    }
  }

  mostrarResumen(){
    this.router.navigate(['resumen', this.route.snapshot.params.mesaId]);
  }

  mostrarGrupos(){
    this.miservicio.getGrupos().pipe(first()).subscribe(datos=>{
      //console.log(datos)
      this.listGrupo=datos;
    })
  }

  muestraArticulos(g){
    //console.log(g)
    this.router.navigate(['articulo', g.intId, this.route.snapshot.params.mesaId]);
  }

}
