import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ComandasCamareroService } from '../services/comandas-camarero.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class APIResolver implements Resolve<any> {
  constructor(private apiService: ComandasCamareroService,private storageService: StorageService) {}

  resolve(route: ActivatedRouteSnapshot) {
      //console.log(route);

      //paso idZona
      if(route.params.intId!=null){
        return this.apiService.getMesas(0,route.params.intId);
      }

      //paso id mesa
      else if(route.params.dato!=null){
        return this.apiService.getCabeceraPedido('','N',route.params.dato);
      }
      
      //paso id grupo
      else if(route.params.grupoId!=null){        
        return this.apiService.getArticulos(route.params.mesa,0,'',route.params.grupoId);
      }
    
      //paso el id mesa para poder volver al resumen
      else if(route.params.idMesa!=null){
        return this.apiService.getComplementos(route.params.linea);
      }            
  }
}