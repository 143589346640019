import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Session } from '../core/models/session.model';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string;
  mesa:string;
  datos:Session[]=[];

  constructor(private router: Router,private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
      this.mesa=params["mes"];
    });
   }

  ngOnInit() {
    sessionStorage.setItem('strCookieServiciosMesa', this.cookie);
    sessionStorage.setItem('intMesaIdServiciosMesa', this.mesa);

    setTimeout(() => {
      //cargo los datos de la cookie en la sesion y redirijo a la carta online
      //this.correctLogin(this.datos);
      this.router.navigate(['/comandas']);
    }, 3000);
  }

}
