import { Component, OnInit } from '@angular/core';
import { ComandasCamareroService } from '../core/services/comandas-camarero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Articulo } from '../core/models/articulo';
import { first } from 'rxjs/operators';
import { StorageService } from '../core/services/storage.service';

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.scss']
})
export class ArticuloComponent implements OnInit {

  listArticulos:Articulo[]=[];
  breakpoint:any;
  grupo:any;
  mesa:any;
  pedidoVacio:boolean=false;
  totalArticulos:number;
  muestraCarrito:boolean=false;
  numPedido:number;
  zonaCabecera:boolean=false;
  muestraTotalArticulos:boolean=false;
  muestraSpinner:boolean=false;
  muestraSpinnerGrande:boolean=false;

  constructor(private router:Router,private route: ActivatedRoute,private miservicio:ComandasCamareroService,private storageService: StorageService) { }

  ngOnInit() {
    //console.log(this.route.snapshot.params);
    // console.log(this.route.snapshot.data.items.miListArticulo);
    
    this.recuperaMesa();
    this.recuperoNombreMesa();
    // this.breakpoint = (window.innerWidth <= 500 && window.innerWidth > 350) ? 1 : 3;
    this.breakpoint = (window.innerWidth <= 500) ? 3 : 6;

    if(this.route.snapshot.data.items.miRespuesta.booOk){
      this.grupo=this.route.snapshot.data.items.miListArticulo[0].strGrupoNombre;
      this.listArticulos=this.route.snapshot.data.items.miListArticulo;
    }else{
      this.pedidoVacio=true;
    }

    //RECUPERO EL NUMERO DEL PEDIDO

    //PIDO EL NUMERO DE ARTICULOS QUE TIENE UN PEDIDO
    setTimeout(() => {
      this.recuperoTotalArticulos();
      
    }, 800);
  
    
  }

  recuperoNombreMesa(){
    // console.log(this.route.snapshot.params.mesa)
    this.miservicio.getMesas(this.route.snapshot.params.mesa,0).subscribe((datos:any)=>{
      // console.log(datos)
      this.mesa=datos[0].strNombre;
    })
  }

  //recupero datos de la cabecera
  recuperaMesa(){
    this.miservicio.getCabeceraPedido('','N',this.route.snapshot.params.mesa).subscribe((datos:any)=>{
      if(datos.length>0){
        // this.mesa=datos[0].strMesaNombre;
        this.numPedido=datos[0].intId;
      }      
    });
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 500) ? 3 : 6;
  }

  muestraGrupos(){
    this.router.navigate(['grupo', this.route.snapshot.params.mesa]);
  }

  mostrarResumen(){
    this.router.navigate(['resumen', this.route.snapshot.params.mesa]);
  }

  insertaLinea(a){
    this.muestraSpinner=true;
    this.muestraSpinnerGrande=true;
    //console.log(a)
    // console.log(this.storageService.getCurrentSession().user.strEntidadId)
    this.miservicio.insertaLineaPedido(this.route.snapshot.params.mesa,a.intId,this.numPedido).subscribe((datos:any)=>{
      // console.log(datos)
      if(datos.booOk){
        this.muestraSpinner=false;
        this.muestraSpinnerGrande=false;
        // this.muestraTotalArticulos=true;
        this.numPedido=datos.strValor;
        this.recuperoTotalArticulos();
      }
    })
  }

  recuperoTotalArticulos(){
    // console.log(this.numPedido)
    if(this.numPedido===undefined){
      this.miservicio.getCabeceraPedido('','N',this.route.snapshot.params.mesa).subscribe((datos:any)=>{
        // console.log(datos);
        if (datos.length>0){
          this.numPedido=datos[0].intId;

        this.miservicio.getLineasPedido(this.numPedido,this.route.snapshot.params.mesa).subscribe((datos:any)=>{
          // console.log(datos)
          if(datos.length>0){
            this.totalArticulos=datos.pop().decTotalArticulos
            this.muestraTotalArticulos=true;     
            this.muestraSpinner=false;           
            this.muestraSpinnerGrande=false;
          }
        })
        }      
      })
    }else{
      this.miservicio.getLineasPedido(this.numPedido,this.route.snapshot.params.mesa).subscribe((datos:any)=>{
        // console.log(datos)
        if(datos.length>0){
          this.totalArticulos=datos.pop().decTotalArticulos
          this.muestraTotalArticulos=true;     
          this.muestraSpinner=false;           
          this.muestraSpinnerGrande=false;
        }
      })
    }   
  }
}
